import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import useOnsiteSnackbarNotifications from 'web/src/modules/customer-notifications/composables/useOnsiteSnackbarNotifications';
import CustomerNotificationsModalNewMessages from '../CustomerNotificationsModalNewMessages/CustomerNotificationsModalNewMessages.vue';
import CustomerNotificationsModalOnSite from '../CustomerNotificationsModalOnSite/CustomerNotificationsModalOnSite.vue';
import CustomerNotificationsModalFortuneWheel from '../CustomerNotificationsModalFortuneWheel/CustomerNotificationsModalFortuneWheel.vue';
import CustomerNotificationsModalBonuses from '../CustomerNotificationsModalBonuses/CustomerNotificationsModalBonuses.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationsModalController',
    setup (__props) {
        useOnsiteSnackbarNotifications();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createVNode(CustomerNotificationsModalNewMessages),
                _createVNode(CustomerNotificationsModalOnSite),
                _createVNode(CustomerNotificationsModalBonuses),
                _createVNode(CustomerNotificationsModalFortuneWheel)
            ])), [
                [
                    _directive_auto_id,
                    'CustomerNotificationsModalController'
                ]
            ]);
        };
    }
});
