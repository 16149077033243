import { computed, onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { CampaignState, CustomerConfig } from '@leon-hub/api-sdk/src/sdk/sdk';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFortuneWheelStore } from 'web/src/modules/fortune-wheel/store';
import { useUserStore } from 'web/src/modules/user/store';
export default function useCustomerNotificationsModalFortuneWheel() {
    const { $translate } = useI18n();
    const router = useRouter();
    const fortuneWheelStore = useFortuneWheelStore();
    const campaignState = toRef(fortuneWheelStore, 'campaignState');
    const categoryId = toRef(fortuneWheelStore, 'categoryId');
    const actionUrl = toRef(fortuneWheelStore, 'actionUrl');
    const showNotification = toRef(fortuneWheelStore, 'showNotification');
    const isAdditionalRoundActive = toRef(fortuneWheelStore, 'isAdditionalRoundActive');
    const { fetchActiveOrAvailableCampaigns } = useFortuneWheelStore();
    const showFortuneWheelDialog = ref(true);
    const modalShowFortuneWheelWindow = computed(()=>({
            isAlert: true,
            modal: {
                isOverlayCloseAllowed: false,
                width: ModalWidth.SMALL,
                title: isAdditionalRoundActive.value ? $translate('WEB2_PROMOTIONS_FORTUNE_WHEEL_CONGRATULATION').value : $translate('WEB2_WELCOME_TO').value,
                confirmMessage: isAdditionalRoundActive.value ? $translate('WEB2_PROMOTIONS_FORTUNE_WHEEL_CONGRATULATION_MESSAGE').value : $translate('WEB2_WELCOME_FORTUNE_WHEEL_MSG').value,
                buttons: [
                    {
                        kind: ButtonKind.PRIMARY,
                        action: DialogAction.CONFIRM,
                        label: $translate('WEB2_PROMOTIONS_LUCKY_SPIN').value
                    },
                    {
                        kind: ButtonKind.TRANSPARENT,
                        action: DialogAction.MODAL_CLOSE,
                        label: $translate('JSP_PCL_FBOT_CLOSE').value
                    }
                ],
                dataTestId: 'fortune-wheel-notification'
            }
        }));
    const showFortuneWheelWindow = computed(()=>showNotification.value && showFortuneWheelDialog.value || isAdditionalRoundActive.value && showFortuneWheelDialog.value);
    function onClose() {
        showFortuneWheelDialog.value = false;
        useUserStore().setAdditionalPropsUser({
            value: {
                value: true
            },
            config: CustomerConfig.IS_FORTUNE_WHEEL_WINDOW_SHOWN
        });
    }
    function onSpinWheel() {
        onClose();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        if (campaignState.value === CampaignState.ACTIVE) router.push({
            name: RouteName.FORTUNE_WHEEL
        });
        else router.push({
            name: RouteName.PROMOTION_DETAILS,
            params: {
                categoryId: categoryId.value,
                actionUrl: actionUrl.value
            }
        });
    }
    function onButtonClick(param) {
        let { action } = param;
        if (action === DialogAction.CONFIRM) onSpinWheel();
        if (action === DialogAction.MODAL_CLOSE) onClose();
    }
    onMounted(async ()=>{
        await fetchActiveOrAvailableCampaigns();
    });
    return {
        showFortuneWheelWindow,
        modalShowFortuneWheelWindow,
        onClose,
        onButtonClick
    };
}
