import { computed, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useCustomerNotificationsStore } from 'web/src/modules/customer-notifications/store';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
export default function useCustomerNotificationsModalOnSite() {
    const router = useRouter();
    const notificationsStore = useCustomerNotificationsStore();
    const messages = toRef(notificationsStore, 'modalMessages');
    const isCustomerModalsAllowed = toRef(notificationsStore, 'isCustomerModalsAllowed');
    const pendingModals = ref([]);
    const image = ref('');
    const modalOptions = computed(()=>pendingModals.value[0]);
    const title = computed(()=>modalOptions.value?.title);
    const message = computed(()=>modalOptions.value?.message);
    const replacedLinksContent = computed(()=>message.value?.replace(/<a\s/gm, '<VDynamicContentLink use-emitter @click-link="$emit(\'click-link\', $event)" ')?.replace(/\/a>/gm, '/VDynamicContentLink>'));
    const lastImage = computed(()=>modalOptions.value?.image || image.value);
    const isModalEnabled = computed(()=>pendingModals.value.length > 0 && isCustomerModalsAllowed.value);
    const modalProps = computed(()=>{
        const buttons = [
            {
                kind: ButtonKind.PRIMARY,
                action: DialogAction.CONFIRM,
                label: modalOptions.value?.button?.text
            }
        ];
        if (modalOptions.value?.button2?.text) buttons.push({
            kind: ButtonKind.TRANSPARENT,
            action: DialogAction.MODAL_CLOSE,
            label: modalOptions.value?.button2?.text
        });
        return {
            isAlert: true,
            isScrollBarEnabled: true,
            modal: {
                width: ModalWidth.SMALL,
                title: modalOptions.value?.title,
                buttons
            }
        };
    });
    function onClose() {
        modalOptions.value;
        notificationsStore.setRead(modalOptions.value);
        pendingModals.value.shift();
    }
    function onUrlClick(link) {
        if (modalOptions.value) notificationsStore.setClicked(modalOptions.value);
        if (link) {
            if (link.startsWith('http')) window.open(link);
            else router.push(link);
        }
        onClose();
    }
    function onButtonClick(param) {
        let { action } = param;
        if (action === DialogAction.CONFIRM) onUrlClick(modalOptions.value?.button?.link);
        if (action === DialogAction.MODAL_CLOSE) onUrlClick(modalOptions.value?.button2?.link);
    }
    function onBodyLinkClick(param) {
        let { href } = param;
        onUrlClick(href);
    }
    watch(messages, (newValue, oldValue)=>{
        for (const newMessage of newValue)if (!oldValue || !oldValue.some((item)=>item.id === newMessage.id && item.source === newMessage.source)) {
            pendingModals.value.push(newMessage);
            image.value = newMessage.image || '';
        }
    });
    return {
        lastImage,
        isModalEnabled,
        modalProps,
        title,
        message,
        replacedLinksContent,
        onClose,
        onButtonClick,
        onBodyLinkClick
    };
}
