import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { ModalSelector } from 'web/src/modules/core/enums';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import ModalTransition from 'web/src/components/Modal/ModalPortal/enums/ModalTransition';
import { useCustomerNotificationsModalOnSite } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationsModalOnSite',
    setup (__props) {
        const { lastImage, isModalEnabled, modalProps, title, message, replacedLinksContent, onClose, onButtonClick, onBodyLinkClick } = useCustomerNotificationsModalOnSite();
        const transition = void 0;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(ModalSelector).BODY,
                transition: _unref(transition)
            }, {
                default: _withCtx(()=>[
                        _unref(isModalEnabled) ? (_openBlock(), _createBlock(ConfirmModal, _mergeProps({
                            key: 0,
                            class: "fast-track-modal fast-track-cms-wrapper"
                        }, _unref(modalProps), {
                            onClose: _unref(onClose),
                            onButtonClick: _unref(onButtonClick)
                        }), _createSlots({
                            default: _withCtx(()=>[
                                    _unref(message) ? (_openBlock(), _createBlock(VDynamicContent, {
                                        key: 0,
                                        content: _unref(replacedLinksContent),
                                        class: _normalizeClass(_ctx.$style['fast-track-modal__message']),
                                        onClickLink: _unref(onBodyLinkClick)
                                    }, null, 8, [
                                        "content",
                                        "class",
                                        "onClickLink"
                                    ])) : _createCommentVNode("", true)
                                ]),
                            _: 2
                        }, [
                            _unref(lastImage) ? {
                                name: "icon",
                                fn: _withCtx(()=>[
                                        _createElementVNode("img", {
                                            class: _normalizeClass(_ctx.$style['fast-track-modal__image']),
                                            src: _unref(lastImage),
                                            alt: _unref(title)
                                        }, null, 10, _hoisted_1)
                                    ]),
                                key: "0"
                            } : void 0
                        ]), 1040, [
                            "onClose",
                            "onButtonClick"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "selector",
                "transition"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerNotificationsModalOnSite'
                ]
            ]);
        };
    }
});
