import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { ModalSelector } from 'web/src/modules/core/enums';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import ModalTransition from 'web/src/components/Modal/ModalPortal/enums/ModalTransition';
import { useCustomerNotificationsModalNewMessages } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationsModalNewMessages',
    setup (__props) {
        const { isModalEnabled, modalProps, onClose, onButtonClick } = useCustomerNotificationsModalNewMessages();
        const transition = void 0;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(ModalSelector).BODY,
                transition: _unref(transition)
            }, {
                default: _withCtx(()=>[
                        _unref(isModalEnabled) ? (_openBlock(), _createBlock(ConfirmModal, _mergeProps({
                            key: 0
                        }, _unref(modalProps), {
                            onClose: _unref(onClose),
                            onButtonClick: _unref(onButtonClick)
                        }), null, 16, [
                            "onClose",
                            "onButtonClick"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "selector",
                "transition"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerNotificationsModalNewMessages'
                ]
            ]);
        };
    }
});
