import { computed, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { useCustomerNotificationsStore } from 'web/src/modules/customer-notifications/store';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useCustomerNotificationsModalNewMessages() {
    const { $translate } = useI18n();
    const router = useRouter();
    const notificationsStore = useCustomerNotificationsStore();
    const isCustomerModalsAllowed = toRef(notificationsStore, 'isCustomerModalsAllowed');
    const unreadCounter = toRef(notificationsStore, 'unreadCounter');
    const latestUnreadMessage = toRef(notificationsStore, 'latestUnreadMessage');
    const showModal = ref(false);
    const isModalEnabled = computed(()=>showModal.value && isCustomerModalsAllowed.value);
    const modalProps = computed(()=>({
            isAlert: true,
            modal: {
                width: ModalWidth.SMALL,
                iconName: IconName.NOTIFICATION_NEW,
                iconKind: JumbotronIconKind.SUCCESS,
                title: $translate('WEB2_CUSTOMER_MESSAGES_MODAL_TITLE').value,
                confirmMessage: $translate('WEB2_CUSTOMER_MESSAGES_MODAL_DESCRIPTION').value,
                buttons: [
                    {
                        kind: ButtonKind.PRIMARY,
                        action: DialogAction.CONFIRM,
                        label: $translate('WEB2_CUSTOMER_MESSAGES_MODAL_BTN_READ').value
                    },
                    {
                        kind: ButtonKind.TRANSPARENT,
                        action: DialogAction.MODAL_CLOSE,
                        label: $translate('WEB2_CUSTOMER_MESSAGES_MODAL_BTN_LATER').value
                    }
                ]
            }
        }));
    function onClose() {
        notificationsStore.hideModal();
        showModal.value = false;
    }
    async function onConfirm() {
        onClose();
        await notificationsStore.loadMessages();
        let params;
        if (1 === unreadCounter.value && latestUnreadMessage.value) params = {
            id: String(latestUnreadMessage.value.id),
            source: latestUnreadMessage.value.source
        };
        router.push({
            name: RouteName.CUSTOMER_NOTIFICATIONS,
            params
        });
    }
    function onButtonClick(param) {
        let { action } = param;
        if (action === DialogAction.CONFIRM) onConfirm();
        if (action === DialogAction.MODAL_CLOSE) onClose();
    }
    watch(unreadCounter, (newValue)=>{
        if (newValue > 0) {
            const postponedUnreadCounter = notificationsStore.getPostponedUnreadCounter();
            if (newValue - postponedUnreadCounter > 0) showModal.value = true;
        } else if (showModal.value) showModal.value = false;
    });
    return {
        isModalEnabled,
        modalProps,
        onClose,
        onButtonClick
    };
}
