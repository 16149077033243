import { watch, computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { BonusCampaignType, BonusStep, DisplayType, FrontNotificationType, buttonActionClaimCashback } from '@leon-hub/api-sdk';
import { getIconName } from '@leon-hub/icons';
import { sleep } from '@leon-hub/utils';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
import { useSiteConfigStore } from 'web/src/modules/core/store/useSiteConfigStore';
import useProfileBonusesStore from 'web/src/modules/profile/submodules/bonuses/store/useProfileBonusesStore';
export default function useOnsiteSnackbarNotifications() {
    const snackbarsStore = useSnackbarsStore();
    const profileBonusesStore = useProfileBonusesStore();
    const notificationsStore = useFrontNotificationsStore();
    const frontNotifications = toRef(()=>notificationsStore.frontNotifications);
    const siteConfigStore = useSiteConfigStore();
    const duration = toRef(()=>siteConfigStore.cashbackNotificationAutoCloseDuration);
    const egsBonuses = toRef(()=>profileBonusesStore.egsBonuses);
    const router = useRouter();
    let isNotificationBeingShown = false;
    const notificationQueue = [];
    const queuedNotificationIds = new Set();
    const onSiteSnackbarNotification = computed(()=>(frontNotifications.value ?? []).filter((item)=>item.type === FrontNotificationType.ON_SITE).filter((item)=>!item.isRead && item.displayType === DisplayType.SNACKBAR) ?? []);
    watch(onSiteSnackbarNotification, (value)=>{
        if (value.length > 0) {
            const newNotifications = value.filter((notification)=>!queuedNotificationIds.has(notification.id));
            notificationQueue.push(...newNotifications);
            for (const notification of newNotifications)queuedNotificationIds.add(notification.id);
            if (!isNotificationBeingShown) showNextNotification();
        }
    });
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function showNextNotification() {
        if (notificationQueue.length > 0) {
            const notification = notificationQueue.shift();
            if (notification) {
                isNotificationBeingShown = true;
                snackbarsStore.show({
                    id: notification.id,
                    title: notification.title,
                    text: notification.message,
                    iconName: getIconName({
                        name: notification.iconName ?? ''
                    }),
                    image: void 0,
                    type: 'warning',
                    duration: duration.value ?? notification.delay,
                    cancel: {
                        action: async ()=>{
                            await notificationsStore.markNotification({
                                id: notification.id
                            });
                            return Promise.resolve();
                        }
                    },
                    accept: {
                        label: notification?.ctaButtonText ?? '',
                        action: async ()=>{
                            if (notification.buttonAction && notification.buttonAction === buttonActionClaimCashback) {
                                await profileBonusesStore.fetchBonuses(BonusCampaignType.EGS);
                                try {
                                    const bonus = egsBonuses.value.find((b)=>b.step === BonusStep.TRANSFER);
                                    if (bonus?.bonusId) {
                                        profileBonusesStore.setWaitingRefreshDelay(true);
                                        await profileBonusesStore.collectBonus({
                                            bonus
                                        });
                                        profileBonusesStore.setWaitingRefreshDelay(false);
                                        await notificationsStore.markNotification({
                                            id: notification.id
                                        });
                                    }
                                    snackbarsStore.update({
                                        id: notification.id,
                                        accept: {
                                            isDone: true,
                                            label: void 0
                                        }
                                    });
                                } catch  {
                                    return Promise.reject();
                                }
                                await sleep(5000);
                                return Promise.resolve();
                            }
                            router.push(notification?.ctaButtonLink ?? '');
                            await notificationsStore.markNotification({
                                id: notification.id
                            });
                            return Promise.resolve();
                        }
                    },
                    onHide: ()=>{
                        isNotificationBeingShown = false;
                        showNextNotification();
                        return Promise.resolve();
                    }
                });
            }
        }
    }
}
