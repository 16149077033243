import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { ModalSelector } from 'web/src/modules/core/enums';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import ModalTransition from 'web/src/components/Modal/ModalPortal/enums/ModalTransition';
import { useCustomerNotificationsModalBonuses } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationsModalBonuses',
    setup (__props) {
        const { isModalEnabled, modalProps, depositAmountFormatted, bonusAmountFormatted, mainBalance, mainBalanceFormatted, bonusBalanceFormatted, onClose, onButtonClick } = useCustomerNotificationsModalBonuses();
        const transition = void 0;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(ModalSelector).BODY,
                transition: _unref(transition)
            }, {
                default: _withCtx(()=>[
                        _unref(isModalEnabled) ? (_openBlock(), _createBlock(ConfirmModal, _mergeProps({
                            key: 0
                        }, _unref(modalProps), {
                            onClose: _unref(onClose),
                            onButtonClick: _unref(onButtonClick)
                        }), {
                            default: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['customer-notifications-bonuses'])
                                    }, [
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--wrapper'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--text-box'])
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--text'])
                                                }, _toDisplayString(_ctx.$t('WEB2_BONUS_CODE_AND_DEPOSIT')), 3),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--value'])
                                                }, _toDisplayString(_unref(depositAmountFormatted)), 3)
                                            ], 2)
                                        ], 2),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--icon'])
                                        }, [
                                            _createVNode(_unref(VIcon), {
                                                name: _unref(IconName).PLUS
                                            }, null, 8, [
                                                "name"
                                            ])
                                        ], 2),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--wrapper'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass([
                                                    _ctx.$style['customer-notifications-bonuses--text-box'],
                                                    _ctx.$style['right']
                                                ])
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--text'])
                                                }, _toDisplayString(_ctx.$t('LEONSHOP_ORDER_BONUS')), 3),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--value'])
                                                }, _toDisplayString(_unref(bonusAmountFormatted)), 3)
                                            ], 2)
                                        ], 2),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--arrow'])
                                        }, [
                                            _createVNode(_unref(VIcon), {
                                                size: _unref(IconSize).SIZE_12,
                                                name: _unref(IconName).ARROW_DOWNWARD
                                            }, null, 8, [
                                                "size",
                                                "name"
                                            ])
                                        ], 2)
                                    ], 2),
                                    _createElementVNode("div", {
                                        class: _normalizeClass({
                                            [_ctx.$style['customer-notifications-bonuses']]: true,
                                            [_ctx.$style['bottom']]: true,
                                            [_ctx.$style['alone']]: _unref(mainBalance) <= 0
                                        })
                                    }, [
                                        _unref(mainBalance) > 0 ? (_openBlock(), _createElementBlock("div", {
                                            key: 0,
                                            class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--wrapper'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--text-box'])
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--value'])
                                                }, _toDisplayString(_unref(mainBalanceFormatted)), 3),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--text'])
                                                }, _toDisplayString(_ctx.$t('WEB2_FOR_MAIN_BALANCE')), 3)
                                            ], 2)
                                        ], 2)) : _createCommentVNode("", true),
                                        _unref(mainBalance) > 0 ? (_openBlock(), _createElementBlock("div", {
                                            key: 1,
                                            class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--icon'])
                                        }, [
                                            _createVNode(_unref(VIcon), {
                                                name: _unref(IconName).PLUS
                                            }, null, 8, [
                                                "name"
                                            ])
                                        ], 2)) : _createCommentVNode("", true),
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--wrapper'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass([
                                                    _ctx.$style['customer-notifications-bonuses--text-box'],
                                                    _ctx.$style['right']
                                                ])
                                            }, [
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--value'])
                                                }, _toDisplayString(_unref(bonusBalanceFormatted)), 3),
                                                _createElementVNode("div", {
                                                    class: _normalizeClass(_ctx.$style['customer-notifications-bonuses--text'])
                                                }, _toDisplayString(_ctx.$t('WEB2_FOR_BONUS_BALANCE')), 3)
                                            ], 2)
                                        ], 2)
                                    ], 2)
                                ]),
                            _: 1
                        }, 16, [
                            "onClose",
                            "onButtonClick"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "selector",
                "transition"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerNotificationsModalBonuses'
                ]
            ]);
        };
    }
});
