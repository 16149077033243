import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
import ModalTransition from 'web/src/components/Modal/ModalPortal/enums/ModalTransition';
import { useCustomerNotificationsModalFortuneWheel } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerNotificationsModalFortuneWheel',
    setup (__props) {
        const { modalShowFortuneWheelWindow, showFortuneWheelWindow, onClose, onButtonClick } = useCustomerNotificationsModalFortuneWheel();
        const transition = void 0;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(ModalSelector).BODY,
                transition: _unref(transition)
            }, {
                default: _withCtx(()=>[
                        _unref(showFortuneWheelWindow) ? (_openBlock(), _createBlock(ConfirmModal, _mergeProps({
                            key: 0
                        }, _unref(modalShowFortuneWheelWindow), {
                            onClose: _unref(onClose),
                            onButtonClick: _unref(onButtonClick)
                        }), {
                            icon: _withCtx(()=>[
                                    _createElementVNode("img", {
                                        src: require('web/src/assets/images/fortune-wheel/fortune-wheel.svg'),
                                        class: _normalizeClass(_ctx.$style['fortune-wheel__icon']),
                                        alt: ""
                                    }, null, 10, _hoisted_1)
                                ]),
                            _: 1
                        }, 16, [
                            "onClose",
                            "onButtonClick"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "selector",
                "transition"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerNotificationsModalFortuneWheel'
                ]
            ]);
        };
    }
});
