import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { useDesktopModalStore } from 'web/src/modules/core/store';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function useCustomerNotificationsModalBonuses() {
    const { $translate } = useI18n();
    const router = useRouter();
    const formatMoney = useFormatMoney();
    const notificationsStore = useFrontNotificationsStore();
    const cbcFrontNotification = toRef(notificationsStore, 'cbcFrontNotification');
    const desktopModal = toRef(useDesktopModalStore(), 'desktopModal');
    const depositAmount = computed(()=>cbcFrontNotification.value?.depositAmount || 0);
    const bonusAmount = computed(()=>cbcFrontNotification.value?.amount || 0);
    const mainBalance = computed(()=>{
        if (depositAmount.value) return depositAmount.value - (cbcFrontNotification.value?.depositTransferAmount || 0);
        return 0;
    });
    const bonusBalance = computed(()=>bonusAmount.value + (cbcFrontNotification.value?.depositTransferAmount || 0));
    const depositAmountFormatted = computed(()=>formatMoney(depositAmount.value));
    const bonusAmountFormatted = computed(()=>formatMoney(bonusAmount.value));
    const mainBalanceFormatted = computed(()=>formatMoney(mainBalance.value));
    const bonusBalanceFormatted = computed(()=>formatMoney(bonusBalance.value));
    const modalProps = computed(()=>({
            isAlert: true,
            isScrollBarEnabled: true,
            modal: {
                customBackground: 'var(--Layer1)',
                width: ModalWidth.SMALL,
                title: $translate('WEB2_BONUS_ACTIVATED').value,
                iconName: IconName.PROMOS,
                iconKind: JumbotronIconKind.SUCCESS,
                buttons: [
                    {
                        kind: ButtonKind.PRIMARY,
                        action: DialogAction.CONFIRM,
                        label: $translate('WEB2_BONUS_TERMS').value
                    }
                ]
            }
        }));
    const isClosed = ref(false);
    const isModalEnabled = computed(()=>!!cbcFrontNotification.value && desktopModal.value?.preset !== ModalDesktopPreset.DepositDetailsDesktopModal && !isClosed.value);
    function onClose() {
        isClosed.value = true;
        notificationsStore.markNotification({
            id: cbcFrontNotification.value?.id || ''
        });
    }
    function onButtonClick() {
        onClose();
        router.push({
            name: RouteName.PROMOTION_DETAILS,
            params: {
                categoryId: cbcFrontNotification.value?.categoryId || '',
                actionUrl: cbcFrontNotification.value?.actionUrl || ''
            }
        });
    }
    return {
        isModalEnabled,
        modalProps,
        depositAmountFormatted,
        bonusAmountFormatted,
        mainBalance,
        mainBalanceFormatted,
        bonusBalanceFormatted,
        onClose,
        onButtonClick
    };
}
